import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleTab, togglePopup } from "./redux/menuSlice";
import API from "./API";
import NodePopup from "./NodePopup";
import {isDev} from "./isDev";
import './RightPanel.css';

const JobDescriptionFormatter = () => {
  const [jobDescription, setJobDescription] = useState('');
  const [formattedDescription, setFormattedDescription] = useState('');
  const dispatch = useDispatch();
  // const activeTab = useSelector((state) => state.menu.activeTab);
  const activeTab = useSelector((state) => state.menu.activeTab);
  const chosenNodeIndex = useSelector((state) => state.menu.chosenNodeIndex);
  const chosenNode = useSelector(state => state.nodes.nodes[chosenNodeIndex])


  const formatIt = async () => {
    try {
      const { reformatted } = await API.getFormattedTask(jobDescription);
      setFormattedDescription(reformatted);
      dispatch(toggleTab('formatted'));
    } catch (error) {
      console.error('Error formatting job description:', error);
    }
  }

  const originalTab = (
    <div>
      <h3>Original description:</h3>
      <textarea
        style={{ maxHeight: '80px' }}
        placeholder="Enter job description..."
        value={jobDescription}
        onChange={(e) => setJobDescription(e.target.value)}
      />
      <br />
      {jobDescription.length > 0 && (
        <button
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          onClick={formatIt}
        >
          FORMAT
        </button>
      )}
    </div>
  );

  const nodes = useSelector(state => state.nodes)
  const menu = useSelector(state => state.menu)
  const nodesJSON = JSON.stringify(nodes, null, 2)
  const menuJSON = JSON.stringify(menu, null, 2)

  const onCopyClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(nodes))
      .then(() => {
        console.log('NodesJSON copied to clipboard');
      })
      .catch((error) => {
        console.error('Failed to copy nodesJSON to clipboard:', error);
      });
  }

  const formattedTab = (
    <div>
      <h3>Formatted description:</h3>
      <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
      <p>
        <button onClick={onCopyClipboard}>COPY</button>
        {isDev && <>
          {menuJSON}
          {nodesJSON}
        </>}
      </p>
    </div>
  );


  const nodePopupTab = (
    <NodePopup node={chosenNode} isPopupOpen={true} onClose={() => dispatch(togglePopup())} />
  );

  const toggles = <div>
    <button
      style={{ fontWeight: activeTab === 'original' ? 'bold' : 'normal' }}
      onClick={() => dispatch(toggleTab('original'))}
    >
      Original
    </button>
    <button
      style={{ fontWeight: activeTab === 'formatted' ? 'bold' : 'normal' }}
      onClick={() => dispatch(toggleTab('formatted'))}
    >
      Formatted
    </button>
    <button
      style={{ fontWeight: activeTab === 'nodePopup' ? 'bold' : 'normal' }}
      onClick={() => dispatch(toggleTab('nodePopup'))}
    >
      Edit
    </button>
  </div>

  return (
    <div>
      <center>
        <br />
        <br />
        {toggles}
        {/*{isDev ? toggles : ''}*/}
        {activeTab === 'original' ? originalTab : activeTab === 'formatted' ? formattedTab : nodePopupTab}
      </center>
    </div>
  );
};

export default JobDescriptionFormatter;