import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import API from "../API";
import {isOptional} from "../utils";

let initialState = {
  nodes: {},
  nextId: 0,
  isFetched: false,
  lastUpdatedByMe: 0,

  needsFetch: true
};

let BASE_LABOUR_COST = 30;

export const fetchProjects = createAsyncThunk('projects/fetchAll', API.fetchProjects)

export const fetchProjectById = createAsyncThunk(
  'projects/fetchById',
  async ({projectId}) => {
    return await API.get(`/api/projects/${projectId}`);
  },
)

const markUpdate = state => {
  const now = Date.now();
  try {
    state.lastUpdatedByMe = now;
  } catch (e) {
    console.error('FAILED TO MARKUPDATE', e, now)
  }
}

const nodesSlice = createSlice({
  name: 'nodes',
  initialState: initialState,
  extraReducers: builder => {
    builder.addCase(fetchProjectById.fulfilled, (state, action) => {
      const projectData = action.payload;

      const currentDate = state?.lastUpdate || 0
      const newDate = projectData?.lastUpdate || 0

      const hasOutdatedVersion = currentDate < newDate
      const hasNoInfo = state.nextId === 0

      const needsUpdate = hasOutdatedVersion || hasNoInfo

      if (needsUpdate) {
        console.log('hasOutdatedVersion=', hasOutdatedVersion, 'noInfo=', hasNoInfo, 'currentDate', currentDate, 'newDate', newDate)
        state.nodes = projectData.nodes;
        state.nextId = projectData.nextId;
        state.lastUpdate = projectData.lastUpdate
      }
      state.isFetched = true;
    })

    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
  },
  reducers: {
    updateNode: (state, action) => {
      const updatedNode = action.payload;
      state.nodes[updatedNode.id] = updatedNode;
      state.isFetched = false;
      markUpdate(state);
    },
    spawnChildNode: (state, action) => {
      const {parentId, title=''} = action.payload;
      var labourCost = BASE_LABOUR_COST

      const parent = state.nodes[parentId];

      if (parent) {
        labourCost = parent.timeCost.labourCost
      }

      const newNode = {
        id: state.nextId,
        title: title ?? 'Node' + state.nextId,
        timeCost: { amount: 0, unit: 'hours', labourCost },
        notes: [],
        links: [],
        parentId: parentId,
        children: [],
      };
      state.nodes[newNode.id] = newNode;
      if (state.nodes[parentId]) {
        const parent = state.nodes[parentId];

        console.log('parentNode', parent)
        state.nodes[parentId].children.push(newNode.id);
      }
      state.nextId += 1;
      state.isFetched = false;
      markUpdate(state);
    },

    // TODO remove or unify with spawn child node
    spawnSiblingNode: (state, action) => {
      const siblingId = action.payload;
      const siblingNode = state.nodes[siblingId];
      const newSiblingNode = {
        id: state.nextId,
        title: '??',
        timeCost: { amount: 0, unit: 'hours', labourCost: BASE_LABOUR_COST },
        notes: [],
        links: [],
        parentId: siblingNode.parentId,
        children: [],
      };
      state.nodes[newSiblingNode.id] = newSiblingNode;
      state.nextId += 1;

      state.isFetched = false;
      markUpdate(state);
    },
    deleteNode: (state, action) => {
      const nodeId = action.payload;
      const node = state.nodes[nodeId];
      if (node.parentId) {
        const parent = state.nodes[node.parentId];
        parent.children = parent.children.filter((childId) => childId !== nodeId);
      }
      delete state.nodes[nodeId];
      state.isFetched = false;
      markUpdate(state);
    },
    moveNode: (state, action) => {
      const { nodeId, newParentId } = action.payload;
      const node = state.nodes[nodeId];
      const oldParentId = node.parentId;

      if (oldParentId) {
        const oldParent = state.nodes[oldParentId];
        oldParent.children = oldParent.children.filter((childId) => childId !== nodeId);
      }

      node.parentId = newParentId;
      state.nodes[newParentId].children.push(nodeId);
      state.isFetched = false;
      markUpdate(state);
    },
    updateNodeDetails: (state, action) => {
      const { nodeId, details } = action.payload;
      state.nodes[nodeId].details = details;
      state.isFetched = false;
      markUpdate(state);
    },


    addLink: (state, action) => {
      const { nodeId, url } = action.payload;
      const isValidUrl = (url) => {
        const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)*$/;
        return urlPattern.test(url);
      }

      if (isValidUrl(url)) {
        state.nodes[nodeId].links.push({ url });
      } else {
        console.error('Invalid URL format');
      }
      state.isFetched = false;
      markUpdate(state);
    },
    removeLink: (state, action) => {
      const { nodeId, linkIndex } = action.payload;
      state.nodes[nodeId].links.splice(linkIndex, 1);
      state.isFetched = false;
      markUpdate(state);
    },

    deleteSelectedNodes: (state, action) => {
      const { chosenNodeIndex, parentId } = action.payload;

      const isRootNode = !parentId
      if (isRootNode)
        return;

      const deleteNodeAndChildren = (nodeId) => {
        try {
          const node = state.nodes[nodeId]
          const children = node.children || []

          children.forEach(deleteNodeAndChildren)
          delete state.nodes[nodeId];
        } catch (e) {

        }

        // TODO 1) do it recursively (start from grandest children)
        // TODO 2) tweak parent.children array
        // TODO ALTERNATIVELY: Just archive nodes? flag em
      };

      state.nodes[parentId].children = state.nodes[parentId].children.filter(c => c !== chosenNodeIndex)
      // console.log('removed from children list of', state.nodes[chosenNodeIndex]?.title)
      deleteNodeAndChildren(chosenNodeIndex);

      state.isFetched = false;
      markUpdate(state);
    },

    changeTimeCost: (state, action) => {
      const { nodeId, newTimeCost } = action.payload;
      state.nodes[nodeId].timeCost = newTimeCost;

      state.isFetched = false;
      markUpdate(state);
    },

    changeLaborCost: (state, action) => {
      const { nodeId, newLaborCost } = action.payload;

      const changeTo = (index) => {
        try {
          const n = state.nodes[index]
          n.timeCost.labourCost = newLaborCost;

          n.children.forEach(changeTo)
        } catch (e) {
          console.error('CAUGHT EX', e, 'cannot change', index)
        }
      }
      // state.nodes[nodeId].timeCost.labourCost = newLaborCost;

      changeTo(nodeId)
      state.isFetched = false;
      markUpdate(state);
    },
  },

  selectors: {
    calculateTotalTimeCost: createSelector(
      (state) => state.nodes,
      (_, nodeId) => nodeId,
      (nodes, nodeId) => {
        const calculateNodeTimeCost = (nodeId) => {
          const node = nodes[nodeId];

          if (isOptional(node))
            return 0;

          const children = node?.children ?? []

          const childTimeCosts = children.map((childId) => {
            try {
              return calculateNodeTimeCost(childId)
            } catch (e) {
              return 0
            }
          });
          const childTotalTimeCost = childTimeCosts.reduce((acc, curr) => acc + curr, 0);
          const timeMultiplier = node.timeCost.unit === 'days' ? 8 : 1;
          return parseFloat(node.timeCost.amount) * timeMultiplier + childTotalTimeCost;
        };

        return calculateNodeTimeCost(nodeId);
      }
    ),

    calculateTotalMoneyCost: createSelector(
      (state) => state.nodes,
      (_, nodeId) => nodeId,
      (nodes, nodeId) => {
        const calculateNodeMoneyCost = (nodeId) => {
          const node = nodes[nodeId];

          if (isOptional(node))
            return 0;

          const children = node?.children ?? [];

          const childMoneyCosts = children.map((childId) => {
            try {
              return calculateNodeMoneyCost(childId);
            } catch (e) {
              return 0;
            }
          });
          const childTotalMoneyCost = childMoneyCosts.reduce((acc, curr) => acc + curr, 0);
          const timeMultiplier = node.timeCost.unit === 'days' ? 8 : 1;
          return parseFloat(node.timeCost.amount) * timeMultiplier * node.timeCost.labourCost + childTotalMoneyCost;
        };

        return calculateNodeMoneyCost(nodeId);
      }
    )
  },
});


export const { updateNode, spawnChildNode, spawnSiblingNode, deleteNode, moveNode, updateNodeDetails, addLink, removeLink, calculateTotalCost, deleteSelectedNodes, changeTitle, changeTimeCost, changeLaborCost, saveUpdates } = nodesSlice.actions;
export const { calculateTotalTimeCost, calculateTotalMoneyCost } = nodesSlice.selectors;
export default nodesSlice.reducer;